import React from 'react'

export const mainContent = [
  {
    content: [
      {
        text: [
          'These terms apply to customers in our Global service region (using www.avica.link).',
          'These Terms of Service (“Terms”) between You (defined below) and Avica (defined below) describe the terms and conditions of Your use of Avica’s Services (defined below). BY COMPLETING THE ELECTRONIC ACCEPTANCE PROCESS, YOU AFFIRM THAT YOU (i) ARE AT LEAST 18 YEARS OF AGE OR OLDER AND (ii) HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY ALL OF THESE TERMS. IF YOU ARE ENTERING INTO THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH COMPANY OR OTHER LEGAL ENTITY TO THESE TERMS, IN WHICH CASE THE TERMS "YOU" OR "YOUR" AS DEFINED HEREIN SHALL REFER TO SUCH ENTITY. IF YOU DO NOT AGREE TO ALL OF THE TERMS, DO NOT PROCEED ON THE ELECTRONIC ACCEPTANCE PROCESS AND YOUR REGISTRATION PROCESS WILL BE DISCONTINUED.',
          <>
            Avica reserves the right, exercised at its sole discretion, to
            modify, add or delete portions of these Terms from time to time, and
            You further agree to be bound by such modified Terms. The most
            current version of the Terms can be viewed at{' '}
            <a
              className="link"
              href="https://www.avica.link/terms-and-conditions.html"
              target="_blank"
              rel="noreferrer nofollow"
            >
              https://www.avica.link/terms-and-conditions.html
            </a>
          </>,
        ],
      },
    ],
  },
  {
    title: '1. DEFINITIONS',
    content: [
      {
        text: [
          '“Affiliate” means where one party controls or jointly controls the other party or exerts significant influence on the other party, and two or more parties are controlled or jointly controlled by the same party, they constitute related parties. For purposes of this definition, "control" means direct or indirect possession of the power to direct or cause the direction of the management and policies of an entity, whether through the ownership of voting securities, by contract or otherwise. An entity shall be considered an "Affiliate" only so long as that entity meets the foregoing definition. Affiliated companies include but are not limited to RAYWING PTE.LTD..',
          'RAYWING PTE.LTD. has the right to transfer all or part of the rights and obligations of this agreement to its affiliated companies.',
          "AVICA may modify and change AVICA’s service content, charging methods and other content according to the actual business situation. For the aforementioned modifications and changes, AVICA will publicize them on the corresponding official website, user terminal or other reasonable means. From the date of AVICA's announcement, if you continue to operate on the AVICA platform (including but not limited to payment behavior or continued use of this service, etc.), you are deemed to agree to the above changes.",
          '“Documentation” means any electronic or printed materials made available to accompany the Software and/or Services that provide instructions for installation, operation, maintenance, and use thereof, as may be updated from time to time by Avica.',
          '“Intellectual Property Rights” means all intellectual property rights or similar proprietary rights, including (a) patent rights and utility models, (b) copyrights and database rights, (c) trademarks, trade names, domain names and trade dress and the goodwill associated therewith, (d) trade secrets, (e) mask works, and (f) industrial design rights; in each case, including any registrations of, applications to register, and renewals and extensions of, any of the foregoing in any jurisdiction in the world.',
          '“Licensed User” means an individual with a valid seat license for the Avica Pro.  Avica Business line of products.',
          '“Malicious Code” means viruses, worms, time bombs, Trojan horses and other harmful or malicious code, files, scripts, agents or programs.',
          '“Services” means the services and related Software provided by Avica or any Avica Affiliate to You under these Terms including Avica Pro, the Avica Business Products.',
          '“Software” means certain software applications, in object code format only, that are required for You to use the Services and licensed to You subject to these Terms.',
          '“Avica” means  RAYWING PTE.LTD., Its Affiliates as defined herein, and its successors and assigns.',
          '“Avica Account” means a user account created with Avica that uniquely identifies You with a username and password.',
          '“Third Party Software” means certain software that Avica license from third parties and provide to You in connection with the Services and incorporated into the Software.',
          '“You or Your” means you as a Licensed User or an employee or agent of a legal entity that is authorized to represent and legally bind such entity to these Terms.',
        ],
      },
    ],
  },
  {
    title: '2. ONLINE REGISTRATION',
    content: [
      {
        text: [
          'To use the Services, You may be required to complete the online registration process, including Your electronic acceptance of these Terms. Avica may reject an online registration at its sole discretion and is not obligated to provide a reason for its rejection.',
          'a. Registration Data. As part of the online registration process for an Avica Account, Avica will collect certain limited information about You (“Registration Data”). All Registration Data provided by You must be current, complete, and accurate, and You are solely responsible for updating the Registration Data as necessary. Avica may terminate all rights to access, receive, use and license the Services if (i) Avica discovers that any of Your Registration Data is incomplete, inaccurate, or not current, or (ii) Avica determines, at its sole discretion, that You are not the appropriate user of the Services.',
          'b. Passwords. Except for Avica Secure Workspace and Avica Vault, as part of the online registration process, You must use Your email address as Your username and choose a password for access to Your Avica Account. You are entirely responsible for maintaining the confidentiality of Your password and agree to carefully safeguard all of Your passwords. You are solely responsible for any and all activities that occur under Your Avica Account and agree to immediately notify Avica of any unauthorized use of Your Avica Account or any other breach of security. Furthermore, You are solely responsible for obtaining consent from Other Users of your Avica Account before collecting, transmitting or transferring any content from their devices through the Services. Avica shall not be liable for any loss that You may incur as a result of a third party using Your Avica Account, either with or without Your knowledge. You may be held liable for losses incurred by Avica or another party due to a third party using Your Avica Account, either with or without Your knowledge, or Your failure to comply with the terms set forth in this section.',
          'c. Security. Avica will use commercially reasonable efforts to maintain appropriate administrative, physical and technical safeguards for protection of the security, confidentiality and integrity of Registration Data. Notwithstanding the foregoing, You acknowledge that, notwithstanding any security precautions deployed by Avica, the use of, or connection to, the internet provides the opportunity for unauthorized third parties to circumvent such precautions and illegally gain access to the Services and Registration Data. Avica cannot and does not guarantee the privacy, security, integrity or authenticity of any information transmitted over or stored in any system connected to or accessible via the internet or otherwise or that any such security precautions will be adequate or sufficient.',
          <>
            d. Subscription to Use the Software. When You subscribe to use the
            Services, You are agreeing to the Avica Terms of Sale, which can be
            viewed at (1)
            <a
              className="link"
              href="https://www.avica.link/term-of-sale.html"
              target="_blank"
              rel="noreferrer nofollow"
            >
              https://www.avica.link/term-of-sale.html
            </a>{' '}
            for Avica Pro, Avica Business Products.
          </>,
          'e. Trial and Promotional Offers. From time to time, Avica may offer certain trial and/or promotional offers. Avica reserves the right to modify or discontinue any trial or promotional offers at its sole discretion and without notice. Any trial or promotional offers are limited to account and may not be combined with any other offers.',
        ],
      },
    ],
  },
  {
    title: '3. PAYMENTS AND TAX LIABILITY',
    content: [
      {
        text: [
          'Your acceptance of these Terms constitutes Your agreement to make timely payment(s) due to Avica including, where applicable, any and all state and local taxes, duties and fees. Unless stated otherwise, all prices and fees shown by Avica are exclusive of taxes and regulatory fees. Where applicable, taxes and regulatory fees will be charged on the invoices electronically issued by Avica in accordance with local laws and regulations.  Avica, at its sole discretion, will calculate the amount of taxes due. The taxes and regulatory fees charged can be changed without notice.',
          <span className="unordered-item">
            <span className="dot-bold">Tax exemptions.</span> If You are exempt
            from any taxes or fees, You will be responsible for providing Avica
            with all appropriate tax exemption certificates and/or other
            documentation satisfactory to the applicable taxing authorities to
            substantiate such exemption status. Avica reserves the right to
            review and validate tax exemption documentation.
          </span>,
          <span className="unordered-item">
            <span className="dot-bold">Payment of Taxes and Fees.</span> You
            will pay to Avica any applicable taxes and fees. You are solely
            responsible for paying any and all taxes and fees owing as a result
            of Avica’s provision of the Services to You. If You are required to
            pay any taxes and fees, You shall pay such amounts with no reduction
            or offset in amounts payable to Avica hereunder and You will pay and
            bear such additional amount, as shall be necessary to ensure that
            Avica receives the full amount of payment required as if no such
            reduction or offset were required.
          </span>,
          <span className="unordered-item">
            <span className="dot-bold">Tax determination.</span> Tax
            determination is principally based on the location where You have
            established Your business, or for individuals where that individual
            permanently resides. Avica reserves the right to cross reference
            this location against other available evidence to validate whether
            Your location is accurate. In the event that Your location is
            inaccurate, Avica reserves the right to charge You any outstanding
            taxes and fees.
          </span>,
          'Avica may suspend or terminate the Services on Your Avica Account due to any payment delinquency and You agree to reimburse Avica for all reasonable costs and expenses incurred in collecting such delinquent amounts.',
        ],
      },
    ],
  },
  {
    title: '4. END USER LICENSE AGREEMENT',
    content: [
      {
        text: [
          'These end user license terms grant You a right and license to use the Software under certain limits, restrictions, terms and conditions (“EULA”). You agree to be bound by this EULA before using the Software.',
          'a. License Grant. You are granted a non-transferable, non-sublicensable, non-exclusive license to use the Software and Documentation, subject to Your full compliance with this Section 4.',
          'b. Commercial Use. The Avica Business Products, Avica Pro Products are fully licensed for commercial use in a professional environment. Avica Free is for non-commercial use only, i.e. use it to access Your personal computers for non-work related purposes.',
          'c. Software Restrictions. You shall not, directly or indirectly, nor shall You cause or permit any other person to: (i) in whole or in part, copy, reproduce, transfer, create derivative works from, translate, reverse engineer, disassemble, decompile, or otherwise attempt to derive the source code or underlying ideas or algorithms of Avica; (ii) alter, modify or create derivative works based on the Software, or remove any portion thereof; (iii) remove, alter, cover or obfuscate any copyright notices or other proprietary rights notices placed or embedded by Avica on or in any Software or Documentation; (iv) sell, resell, rent, lease, lend, distribute, assign, or otherwise transfer Your rights to use the Software or Documentation or use it for commercial time sharing, rental, service bureau use or any other form of use for the benefit of any person or entity other than You; (v) use the Software or any component thereof for any illegal purposes; or (vi) use the Software or Documentation, or any component thereof, to enable copyright protection-circumvention devices or to violate or circumvent in any manner any content copyright, content protection scheme, or content copy policies; (vii) publish or disclose to third parties any evaluation of the Services without Avica’s prior written consent; (viii) use the Services for any other purpose than its intended purpose; (ix) interfere with or disrupt the integrity or performance of the Services; (x) introduce any Open Source Software into the Services; or (xi) attempt to gain unauthorized access to the Services or related systems or networks.',
          'd. Service Restrictions. By using the Services, You agree not to and shall not allow any of Other Users of your Avica Account to (i) use the Services in violation of any applicable laws or regulations, (ii) transmit any material that may violate or infringe the intellectual property, privacy or other rights of any third party, (iii) harvest or otherwise collect or store any information of a third party without his/her consent, (iv) use the Services in a way that may cause harm or disruption to Avica network, Avica Accounts or other Avica services or (v) use the Services to send any spam, malware or any fraudulent, obscene or unlawful content.',
          'e. Use Limitations*. You agree to comply with the following applicable terms while using the respective Services:',
          'Avica may, at its sole discretion, suspend any account in violation of this provision or require You to purchase additional licenses to correct any overage.',
          'f. Third Party Software. Certain Third Party Software provided in or with the Software is subject to various other terms and conditions imposed by the licensors of such Third Party Software. Your use of the Third Party Software is subject to and governed by the respective Third Party Software licenses, which are relevant licenses for such Third Party Software You may view from within such Software. Any acquisition by you of such Third Party Software, including any exchange of data between You and any provider of Third Party Software, is solely between You and the applicable provider of the Third Party Software. Avica does not warrant, support or assume any liability for any Third Party Software, regardless of whether or not such Third Party Software is designated by Avica as “certified” or otherwise, except as specified in writing in an order form or Documentation.',
          'The Services may contain features designed to interoperate with Third Party Software (e.g., Google, Facebook or Twitter applications). To use such features, You may be required to obtain access to such Third Party Software from their providers. If the provider of any Third Party Software ceases to make the Third Party Software available for interoperation with the corresponding Service features on reasonable terms, Avica may cease providing such features without notice, and You acknowledge that You will not be entitled to any refund, credit, or other compensation resulting therefrom.',
          'Avica may use the services of one or more third parties to deliver any part of the Services. You agree to comply with any acceptable use policies and other terms of any Third Party Software provider that are provided or otherwise made available to You through such Third Party Software from time to time.',
          'g. Reservation of Rights. Except as expressly granted in these Terms, there are no other licenses granted to You, express, implied or by way of estoppel. All rights not granted in these Terms are reserved by Avica.',
        ],
      },
    ],
  },
  {
    title: '5. INTELLECTUAL PROPERTY PROTECTION',
    content: [
      {
        text: [
          'Avica or its licensors retain ownership of all Intellectual Property Rights in or associated with the Services that are protected by Singapore and international copyright and other intellectual property laws and international trade provisions. You further acknowledge that the Services may contain unpublished information and embody valuable trade secrets proprietary to Avica and/or its licensors. Avica and/or its licensors reserve all rights in the Services not expressly granted herein. The license granted hereunder and Your right to use the Services terminate automatically if You violate any part of the Terms.',
        ],
      },
    ],
  },
  {
    title: '6. CONFIDENTIAL INFORMATION',
    content: [
      {
        text: [
          '“Confidential Information” means any non-public business or technical information of Avica including, without limitation, any information relating to Avica’s trade secrets or know-how that is designated as “confidential,” either orally or in writing, or that You know or should know is considered confidential or proprietary by Avica. You agree to maintain the Confidential Information in strict confidence and not to use Confidential Information except as expressly authorized by these Terms. You shall ensure that no unauthorized persons shall have access to the Confidential Information. Notwithstanding the foregoing, Confidential Information shall not include information that (i) becomes part of the public domain through no breach of the Terms by You directly or indirectly; (ii) is independently developed by You without reference to any Confidential Information.',
        ],
      },
    ],
  },
  {
    title: '7. TRADEMARKS',
    content: [
      {
        text: [
          'You acknowledge and agree that the term Avica and other related logos and designs provided hereunder (collectively, the “Avica Trademarks”) are the exclusive trademarks of Avica, registered in China and elsewhere, and that You shall not use or reproduce the Avica Trademarks without first obtaining a trademark license from Avica. All other trademarks and service marks referenced in the Services or Avica website are the exclusive property of their respective owners. All rights reserved.',
        ],
      },
    ],
  },
  {
    title: '8. PRIVACY',
    content: [
      {
        text: [
          <>
            Avica’s use of any information provided by You, including without
            limitation, Registration Data and payment information, is set forth
            in Avica’s Privacy Policy currently in force, which can be found at{' '}
            <a
              className="link"
              href="https://www.avica.link/privacy-policy.html"
              target="_blank"
              rel="noreferrer nofollow"
            >
              https://www.avica.link/privacy-policy.html
            </a>
          </>,
        ],
      },
    ],
  },
  {
    title: '9. UPDATES AND SUPPORT',
    content: [
      {
        text: [
          'Avica may, from time to time, at its sole discretion, and without any obligation to do so, make updates to the Services available via the Internet or other sources. All such updates shall be deemed to be included within the definition of Services and shall be subject to these Terms. Avica reserves the right to charge fees for any future versions of, or updates to, the Services. If it involves changes in service content and service fees, we will make a public announcement on Avica Official website. If you do not agree, please stop using our services. If you continue to use our services, you are deemed to have agreed.',
        ],
      },
    ],
  },
  {
    title: '10. TERM AND TERMINATION',
    content: [
      {
        text: [
          'The User Agreement takes effect on the day you check the box to agree and use the service. These terms continue until the termination of Your Avica Account. You may terminate these Terms at any time by terminating Your subscription, removing the Software and Documentation from Your system, deleting Your Avica Account, and stopping to use the Services. If you choose to terminate the service, we will delete your user data in accordance with the related Laws and the agreement between Avica and you.',
          'For Avica subscriptions paid via www.avica.link, You may terminate Your subscription by logging into your Avica account at www.avica.link, clicking on the “Subscriptions” tab under “License,” and turning off auto-renewal. Your subscription will no longer auto-renew, and Your account will not be charged automatically at the end of Your current subscription period. The Products You have purchased will remain usable until the end of the current subscription period.',
          'For Avica subscriptions procured via Avica sales personnel, You may cancel Your subscription by providing written notice of Your intent not to renew in the manner set forth in Section 16(e) herein, sent no later than thirty (30) days prior to automatic renewal.',
          'Avica may immediately terminate these Terms and Your subscription, license, and right to the Services if (i) You breach these Terms; (ii) You, as a legal entity, declare bankruptcy, are involved in any bankruptcy proceedings or are otherwise insolvent; or (iii) Avica decides, at its sole discretion, to discontinue offering the Services, in which case Avica shall notify You in advance, whenever possible, and provide You with alternative plans or options to minimize any inconvenience that may be caused by such termination. Avica shall not be liable for any damages resulting from a termination of these Terms as provided for herein. Upon termination of these Terms: (a) all license rights granted hereunder will automatically terminate without further notice to You; and (b) You will immediately discontinue all access to and use of the Services and destroy the Software and Documentation, and all copies thereof. Sections 1, 5, 6, 7, 8, 10, 11, 12, 13, 14 and 16 shall survive the expiration or termination of these Terms in full force and effect.',
        ],
      },
    ],
  },
  {
    title: '11. DISCLAIMER OF WARRANTIES',
    content: [
      {
        text: [
          'THE SERVICES, SOFTWARE, ANY UPDATES THERETO, ANY DOCUMENTATION AND INFORMATION ARE PROVIDED “AS-IS” WITHOUT WARRANTY OF ANY KIND. Avica, ON BEHALF OF ITSELF AND ITS LICENSORS, DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH THESE TERMS AND ANY SAMPLE, SPECIFICATION OR PROPOSAL PROVIDED BY Avica, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS. Avica DOES NOT GUARANTEE THE ACCURACY, COMPLETENESS, SATISFACTORY QUALITY OF THE SERVICES OR THAT THE SERVICES WILL BE FREE OF DEFECTS, RUN ERROR-FREE OR UNINTERRUPTED, MEET YOUR REQUIREMENTS, BE FREE OF VIRUSES OR THAT Avica WILL CORRECT ALL ERRORS. YOU UNDERSTAND AND AGREE THAT ANY MATERIAL OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT YOUR SOLE RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL AND/OR DATA. SOME LAWS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO, TO THAT EXTENT, THIS LIMITATION MAY NOT APPLY TO YOU.',
        ],
      },
    ],
  },
  {
    title: '12. LIMITATION OF LIABILITY',
    content: [
      {
        text: [
          'IN NO EVENT SHALL Avica, OR ITS LICENSORS OR SUPPLIERS, BE LIABLE FOR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR INDIRECT DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION, OR OTHER PECUNIARY LOSS) ARISING OUT OR IN CONNECTION WITH THESE TERMS, IRRESPECTIVE OF THE CAUSE OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION) OR PRODUCT LIABILITY, EVEN IF Avica HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO TO THAT EXTENT THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU.',
          'IN NO EVENT WILL Avica’S CUMULATIVE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS EXCEED THE TOTAL FEES PAID BY YOU IN THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE INCIDENT GIVING RISE TO SUCH LIABILITY).',
        ],
      },
    ],
  },
  {
    title: '13. INDEMNIFICATION',
    content: [
      {
        text: [
          'You hereby agree, at Your sole expense, to indemnify, defend and hold Avica and its Affiliates, employees, officers, directors, owners, information providers, agents, licensees, licensors (the “Indemnified Parties”) harmless from and against any and all liabilities, claims, costs, including reasonable attorneys’ fees, incurred by the Indemnified Parties in connection with any demand, claims, action, suit, or loss arising as a result of (a) any breach by You or any Other Users of your Avica Account of these Terms or claims arising from Your Avica Account; (b) any fraud or manipulation by You; (c) a third-party claim, action or allegation of infringement based on information, data, files or other content submitted by You or any Other Users of your Avica Account; or (d) any claims of credit card fraud based on any information released by You or any Other Users of your Avica Account. You agree to use best efforts to cooperate with Avica in the defense of any demand, claim, action or suit. Avica reserves the right to assume the exclusive defense of any matter subject to indemnification by You at Avica’s own expense.',
        ],
      },
    ],
  },
  {
    title: '14. HIGH-RISK USE',
    content: [
      {
        text: [
          'You hereby acknowledge that the Services are not designed or intended for access and/or use in or during high-risk activities and shall not be used in connection with any system where malfunction can reasonably be expected to result in personal injury, death or damage to property, environment, or business. Without limiting the foregoing, the Services shall not be used in connection with any life support system. Avica and its licensors hereby expressly disclaim any express or implied warranty of fitness for such purposes. You agree to hold Avica and its officers, directors, employees, Affiliates, and licensors harmless from any claims or losses resulting from any of the foregoing uses of the Services.',
        ],
      },
    ],
  },
  {
    title: '15. GENERAL',
    content: [
      {
        text: [
          'a. These Terms constitute the entire agreement between You and Avica concerning Your use of the Services and by accepting these Terms, You expressly acknowledge that these Terms supersede any prior or contemporaneous agreements, communications and/or understandings, written or oral, concerning Your use of the Services. Avica will not be bound by any provision of any purchase order, receipt, acceptance, confirmation, correspondence, or otherwise, regardless of whether these Terms are silent on the matter, under any circumstances, unless Avica expressly agrees to the provision in a written, executed document.',
          'b. If any provision of these Terms shall be held to be invalid, illegal or unenforceable, such provision shall be enforced to the maximum extent permitted by law and the remaining provisions shall not be affected.',
          'c. These Terms are governed by the laws of Singapore. You agree that all claims and disputes arising out of or in connection with these Terms shall be resolved exclusively at the Singapore International Arbitration Center.',
          'd. You may not assign or transfer these Terms or any rights or obligations under these Terms. Any assignment or transfer of these Terms made in contravention of the terms hereof shall be null and void. Subject to the foregoing, these Terms shall be binding on and inure to the benefit of the parties’ respective successors and permitted assigns. Avica may assign its rights under these Terms to its Affiliates and to any successor by way of merger, acquisition, consolidation, reorganization, or sale of all or substantially all of its assets that relate to these Terms, without action on Your part, in which case references to Avica herein shall be deemed to refer to the assignee.',
          'e. Notices by Avica to You may be sent to the email address provided by You during the online registration process or otherwise by any means that Avica determines at its sole discretion as likely to come to Your attention. All notices by You to Avica regarding these Terms shall be in writing and sent by express carrier or certified mail at the address of Avica set forth herein.',
          'f. You agree not to bring or participate in any class action lawsuits against Avica or any of its employees or Affiliates. You agree that You will not bring a claim under these Terms more than two (2) years after expiration or termination of these Terms. No waiver of any provision or consent to any action by Avica shall constitute a waiver of any other provision or consent to any other action, whether or not similar. No waiver or consent by Avica shall constitute a continuing waiver or consent except to the extent specifically set forth by Avica in writing.',
          'g. You acknowledge that Avica may provide software, solutions, and services to third parties, including Your competitors, which are the same or similar to the software, solutions, and services provided to You hereunder.',
          'h. Avica will not be responsible for any delay, interruption, or other failure to perform under these Terms due to acts beyond Avica’s reasonable control.',
          <>
            i. If You have any ethical, integrity, safety, security, and/or
            compliance concerns about Avica or its employees, You are encouraged
            to report the incident anonymously at{' '}
            <a className="link" href="mailto:support@avica.link" rel="nofollow">
              support@avica.link
            </a>
          </>,
          'j. Avica has the right to transfer all or part of the rights and obligations of this agreement to its affiliated companies.',
          "k. You understand and agree that Avica may modify and change Avica’s service content, charging methods and other content according to the actual business situation. For the aforementioned modifications and changes, Avica will publicize them on the corresponding official website, user terminal or other reasonable means. From the date of Avica's public announcement, your continued operation on the platform (including but not limited to click to agree, payment behavior or continued use of this service, etc.) is deemed to be your agreement to the above changes. Please read the terms of service content and payment method carefully before your using.",
          'l. When you use Avica services, you agree that Avica processes the personal information you submit to us for Avica services by collecting, storing, using, processing, transmitting, providing, disclosing, deleting, etc. in accordance with the user agreement and privacy agreements after you agree.',
          '* In certain situations, Avica may “grandfather” customers into the use limitations under which they initially subscribed.',
        ],
      },
    ],
  },
  {
    title: 'Contact Us',
    content: [
      {
        text: [
          'By mail: 10 ANSON ROAD#27-18INTERNATIONAL PLAZA SINGAPORE (079903)',
          <a className="link" href="mailto:service@avica.link" rel="nofollow">
            service@avica.link
          </a>,
          'Last updated: January 9, 2024',
          'This agreement may be translated into different languages. In case of any conflicts or inconsistencies between the English version or any translation hereof, the English version shall prevail and govern.',
        ],
      },
    ],
  },
]
